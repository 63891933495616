import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import { LoginViewModel } from '../../viewModel/loginView';
import axios from 'axios';
import { exceptAddress } from 'helpers/exceptAuthenticationAddress';
import { getUserRole, setUserSession } from '../../helpers/utils';

const LoginForm = ({ hasLabel, layout, setToken }) => {
    // State
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        remember: false
    });
    const [showLoading, setShowLoading] = useState(false);
    const [isWrongPsw, setIsWrongPsw] = useState(false);
    const navigate = useNavigate()
    // Handler
    async function handleSubmit() {
        setShowLoading(true);
        let login = new LoginViewModel(formData.email, formData.password);
        let jsonBody = JSON.stringify(login);
        axios.post(`${window.location.origin}/Auth`, login)
            .then(res => {
                setShowLoading(false);
                let {  jwtToken, ...user } = res.data;
                setUserSession(res.data.jwtToken, user);
                let role = getUserRole();
                if (role.includes("External"))
                    navigate('/components/contracts');
                navigate('/');
            }).catch(e => {
                toast.error(e.response.data, {theme: 'colored'});
                setShowLoading(false);
            });
    };


    const handleFieldChange = e => {
        setIsWrongPsw(false);

        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    let checkPath = (path) => {
        return exceptAddress.includes(path)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
           handleSubmit() 
        }
    }

    return (
        <div>
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Email address</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Email address' : ''}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                    onKeyDown={handleKeyDown}
                    className={isWrongPsw ? "is-invalid" : ""}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Password</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Password' : ''}
                    value={formData.password}
                    name="password"
                    onKeyDown={handleKeyDown}
                    onChange={handleFieldChange}
                    type="password"
                    className={isWrongPsw ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                    Invalid email or password.
                </Form.Control.Feedback>
            </Form.Group>

            <Row className="justify-content-between align-items-center">

                <Col xs="auto">
                    <Link
                        onClick={(e) => { setToken('true'); localStorage.setItem('checkPath', checkPath(`/forgot-password`)); }}
                        className="fs--1 mb-0"
                        to={`/forgot-password`}
                    >
                        Forgot your password?
                    </Link>
                </Col>
            </Row>

            <Form.Group>
                <Button
                    type="button"
                    color="primary"
                    className="mt-3 w-100"
                    onClick={handleSubmit}
                    disabled={!formData.email || !formData.password}
                >
                    <Spinner animation="border" variant="default" size="sm" hidden={!showLoading} /> Log in
                </Button>
            </Form.Group>
        </div>
    );
};

LoginForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool,
    setToken: PropTypes.func
};

LoginForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};


export default LoginForm;
